<template>
  <div id="pmsandwich">
    <HeaderBack backto="/peermentoring" />
    <portal to="topbar-text">
      <h3>Sandwich Feedback</h3>
    </portal>

    <h3>Tuesday presentation</h3>
    <details>
      <summary class="list-group-item d-list-item"
        >Documents <span class="badge badge-primary">3</span></summary
      >
      <ul class="documents">
        <li>
          <a href="">&#128441; speech-draft.docx</a>
        </li>
        <li>
          <a href="">&#128443; slides.pptx</a>
        </li>
        <li>
          <a href="">&#127902; first-try.mkv</a>
        </li>
      </ul>
    </details>

    <ol class="feedback">
      <li v-for="(f, i) in feedbacks" v-bind:key="i">
        <div class="form-row">
          <div class="col-auto">
            {{ f.icon }}
          </div>
          <div class="col">
            <TextAreaSuggestion
              v-bind:placeholder="f.placeholder"
              v-bind:suggestions="f.suggestions"
            />
          </div>
        </div>
      </li>
    </ol>
    <button
      class="btn btn-primary"
      v-on:click="alert('FIXME: popup Delivery/Content/Communication')"
    >
      Add more Feedback
    </button>
    <br /><br />
    <button class="btn btn-secondary">
      Send Feedback
    </button>

    <button
      class="bottombutton btn-primary"
      v-on:click="
        alert('FIXME: popup Useful suggestions about giving effective feedback')
      "
    >
      ℹ️
    </button>
  </div>
</template>

<script>
import HeaderBack from "@/components/HeaderBack.vue";
import TextAreaSuggestion from "@/components/TextAreaSuggestion.vue";

export default {
  name: "PeerMentoringSandwich",
  components: {
    HeaderBack,
    TextAreaSuggestion
  },
  data: function() {
    return {
      feedbacks: [
        {
          icon: "👍",
          placeholder: "Give a positive comment.",
          suggestions: [
            {
              name: "Enjoyed",
              text: "I really enjoyed %%. Keep up with the good work!"
            },
            { name: "Great Job", text: "You did a great job on %%." }
          ]
        },
        {
          icon: "👎",
          placeholder: "Point out what can be improved.",
          suggestions: [
            {
              name: "Improve",
              text: "I think we might be able to improve on %%."
            },
            { name: "test", text: "super test awesome lol" }
          ]
        },
        {
          icon: "💡",
          placeholder: "Give an idea.",
          suggestions: [
            { name: "Try this", text: "How would you feel about trying %% ?" },
            { name: "test2", text: "test test ->%%<- test test" }
          ]
        }
      ]
    };
  },
  methods: {
    alert: function(message) {
      alert(message);
    }
  }
};
</script>

<style lang="scss" scoped>
#pmsandwich {
  @extend .text-center;
  margin-bottom: 5em;
}

h1 {
  @extend .text-center;
}

summary {
  display: list-item !important;
}

.feedback {
  @extend .m-0;
  @extend .p-0;
  li {
    @extend .m-0;
    @extend .p-1;
    @extend .pt-3;
    list-style: none;
  }
}

ul.documents {
  @extend .m-0;
  @extend .list-group;

  @extend .px-3;

  li {
    @extend .list-group-item;
    @extend .p-3;
    @extend .m-0;
    list-style: none;

    /* &:nth-child(odd) {
       background-color: #e2e2e2;
       }
     */
    a {
      @extend .stretched-link;
      /* width: 100%; */
    }
    .with {
      @extend .float-right;
    }

    .quote {
      @extend .d-block;
      @extend .font-italic;
      @extend .text-muted;
    }
  }
}

.bottombutton {
  @extend .text-center;
  @extend .p-0;
  @extend .fixed-bottom;
  left: unset;
  right: 1.5em;
  bottom: 13vh;
  width: 2.5em;
  height: 2.5em;
  @extend .btn;
  @extend .badge-pill;
}
</style>
