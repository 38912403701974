<template>
  <div>
    <div class="form-row" v-if="autofillShown">
      <button
        v-for="s in suggestions"
        v-bind:key="s.name"
        v-on:click="fill(s.text)"
      >
        {{ s.name }}
      </button>
    </div>
    <div class="form-row">
      <div class="col">
        <textarea v-bind:placeholder="placeholder" v-model="content" ref="ta">
        </textarea>
      </div>
      <div class="col-auto">
        <button v-on:click="autofillShown = !autofillShown">🪄</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextAreaSuggestion",
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    suggestions: {
      type: Array,
      default: null
    }
  },
  data: function() {
    return {
      autofillShown: false,
      content: ""
    };
  },
  methods: {
    fill: function(text) {
      let ta = this.$refs.ta;
      let curpos = text.indexOf("%%");
      this.content = text.replace("%%", "");
      ta.focus();
      this.$nextTick(function() {
        ta.setSelectionRange(curpos, curpos);
      });
      this.autofillShown = false;
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  @extend .form-control;
}

button {
  @extend .btn;
}
</style>
